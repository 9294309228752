<script setup>
import {useRoute} from 'vue-router';
import {onMounted, onUnmounted, ref} from 'vue';
import axios from "@/axios";
import {showSuccessToast} from "vant";

const route = useRoute();
const id = route.query.id;
const name = route.query.name;

const show = ref(false);

const baseUrl = process.env.VUE_APP_API_BASE_URL;


const comments = ref([]);
const page = ref(1);
const pageSize = 10;
const hasMore = ref(true);
const totalPages = ref(1);
const isLoading = ref(false);

const commentText = ref('');
const uploadedImages = ref([]);


const onClickLeft = () => history.back();

const onClickRight = () => {
  show.value = true
}

const loadComments = async () => {
  if (page.value > totalPages.value || isLoading.value) {
    // hasMore.value = false;
    console.log('已经到底了');
    return;
  }

  isLoading.value = true;
  try {
    // loading.value = true;
    const response = await axios.get('/location-comments', {
      params: {
        locationId: id,
        page: page.value,
        pageSize: pageSize
      }
    });

    totalPages.value = response.data.totalPages;
    if (response.data.data.length) {
      comments.value.push(...response.data.data);
      page.value++;
    }

    console.log(page.value, totalPages.value)

    if (page.value > totalPages.value) {
      console.log('没有更多评论了');
      hasMore.value = false;
    }
  } catch (error) {
    console.error('加载评论失败:', error);
  } finally {
    isLoading.value = false;
  }
}

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  return new Date(dateString).toLocaleDateString('zh-CN', options);
};

const loadMoreTrigger = ref(null);

const createObserver = () => {
  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      console.log('触发加载更多评论');
      loadComments();
    }
  });

  if (loadMoreTrigger.value) {
    observer.observe(loadMoreTrigger.value);
  }

  return observer;
};

const submitForm = async () => {
  console.log("submitForm")
  const formData = new FormData();
  formData.append('userID', 1)
  formData.append('locationID', id)
  formData.append('comment', commentText.value)

  uploadedImages.value.forEach(file => {
    formData.append('files', file.file);
  });

  try {
    const response = await axios.post('/comment', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    showSuccessToast('提交成功');
    window.location.reload();
  } catch (error) {
    console.error('Error:', error);
  }
};

onMounted(() => {
  loadComments();

  const observer = createObserver();

  onUnmounted(() => {
    observer.disconnect();
  });
});
</script>

<template>
  <van-nav-bar
      :title="name"
      left-text="返回"
      right-text="发布"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
  />
  <van-dialog v-model:show="show" title="评论" show-cancel-button @confirm="submitForm">
    <van-form @submit="submitForm">
      <van-field v-model="commentText" label="评论"  type="textarea" placeholder="请输入评论内容" required />

      <van-field label="图片">
        <template #input>
          <van-uploader
              v-model="uploadedImages"
              name="图片"
              multiple
              :max-count="5"
              :preview-size="80"
          />
        </template>
      </van-field>

      <template #footer>
        <van-button type="primary" block native-type="submit">提交</van-button>
      </template>
    </van-form>
  </van-dialog>
  <!--  <header class="app-header">-->
  <!--    <div class="header-content">-->
  <!--    </div>-->
  <!--  </header>-->
  <div class="comment-list">
    <div
        v-for="comment in comments"
        :key="comment.id"
        class="comment-item"
    >
      <div class="user-profile">
        <div class="avatar-container">
          <img :src="`${baseUrl}${comment.user.avatarPath}`" alt="Avatar" class="avatar"/>
        </div>
        <div class="username">
          {{ comment.user.username }}
          <p class="comment-date">{{ formatDate(comment.createdAt) }}
          </p>
        </div>
      </div>
      <p class="comment-text">{{ comment.comment }}</p>
      <div v-if="comment.images && comment.images.length" class="comment-images">
        <img
            v-for="image in comment.images"
            :key="image"
            :src="`${baseUrl}${image}`"
            alt="Comment Image"
            class="comment-image"
        />
      </div>

    </div>
    <div v-if="hasMore" ref="loadMoreTrigger" class="load-more-trigger">
      加载更多...
    </div>
  </div>

  <div v-if="comments.length === 0">
    <van-empty description="还没有评价，快去发表吧" />
  </div>

</template>

<style scoped>
.app-header {
  background: linear-gradient(135deg, #6a11cb, #2575fc),
  url('@/assets/b.png');
  background-blend-mode: overlay;
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  height: 15vh;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
}

.comment-list {
  padding: 16px;
}

.comment-item {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.comment-text {
  font-size: 14px;
  color: #333;
}

.comment-images {
  margin-top: 8px;
}

.comment-image {
  width: 100px;
  height: 100px;
  margin-right: 8px;
  object-fit: cover;
}

.comment-date {
  margin-top: 4px;
  font-size: 12px;
  font-weight: normal;
  color: #999;
}

.load-more-trigger {
  text-align: center;
  padding: 16px;
  color: #007bff;
  cursor: pointer;
}

.user-profile {
  display: flex;
  align-items: center;
}

.avatar-container {
  margin-right: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.username {
  font-size: 14px;
  font-weight: bold;
}

</style>