import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MapView from "@/views/MapView.vue";
import AddView from "@/views/AddView.vue";
import MeView from "@/views/MeView.vue";
import LoginView from "@/views/LoginView.vue";
import DetailView from "@/views/DetailView.vue";
import CommentView from "@/views/CommentView.vue";

import MainLayout from "@/views/MainLayout.vue";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: HomeView
            },
            {
                path: '/comment',
                name: 'comment',
                component: CommentView
            },
            {
                path: '/detail',
                name: 'detail',
                component: DetailView
            },
            {
                path: '/map',
                name: 'map',
                component: MapView
            },
            {
                path: '/add',
                name: 'add',
                component: AddView
            },
            {
                path: '/me',
                name: 'me',
                component: MeView
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
