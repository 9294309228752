<script setup>
import {showToast} from 'vant';
import {onMounted, computed, ref} from 'vue';
import axios from "@/axios";
import {cityList, transform} from '@/utils/utils';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';

const store = useStore();
const selectedCity = computed(() => store.getters.getSelectedCity);
const selectedRegion = computed(() => store.getters.getSelectedRegion);

const rateValue = ref(4.5);
const list = ref([]);
const loading = ref(false);
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const typeValue = ref('');
const value2 = ref('a');
const typeOption = [
  {text: '全部类型', value: ''},
  {text: '卫生间', value: 'wc'},
  {text: '公园', value: 'park'},
  {text: '浴室', value: 'bath'},
  {text: '酒吧', value: 'bar'},
  {text: '其他', value: 'other'}
];
const option2 = [
  {text: '默认排序', value: 'a'},
  {text: '距离优先', value: 'b'},
  {text: '销量排序', value: 'c'},
];

const typeChange = (value) => {
  fetchData({
    "region": selectedRegion.value,
    'latitude': userLatitude.value,
    "longitude": userLongitude.value,
    "keyword": searchQuery.value,
    "type": value
  })

  console.log('Dropdown 1 changed:', value);
  // Perform actions based on value1 change
};


const searchQuery = ref('');

const userLatitude = ref(0.0);
const userLongitude = ref(0.0);

const fetchData = async (params = {}) => {
  try {
    loading.value = true;
    const response = await axios.get('/locations', {
      params: params  // 将传入的参数附加到请求中
    });
    if (response.status === 200) {
      list.value = response.data.data;
      loading.value = false;
    }
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};

const getCityCode = async (params = {}) => {
  try {
    loading.value = true;
    const response = await axios.get('/location-geo', {
      params: params
    });

    if (response.status === 200) {
      selectedRegion.value = response.data.data.cityCode
      selectedCity.value = cityList.city_list[selectedRegion.value]
      console.log("getCityCode", selectedRegion.value)
      console.log("getCityCode", selectedCity.value)

      fetchData({'latitude': userLatitude.value, "longitude": userLongitude.value, "region": selectedRegion.value})
      // list.value = response.data.data;
      // loading.value = false;
    }
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};

const onClick = () => {
  showToast('点击气泡');
};


const triggerSearch = () => {
  if (searchQuery.value.trim()) {
    fetchData({
      "region": selectedRegion.value,
      'latitude': userLatitude.value,
      "longitude": userLongitude.value,
      "keyword": searchQuery.value
    })
  }
}


const router = useRouter();

const navigateToLocation = () => {
  router.push('/add'); // replace '/location' with your target route
};

const showArea = ref(false);
const onAreaConfirm = ({selectedValues, selectedOptions, selectedIndexes}) => {
  console.log(selectedOptions)

  store.commit('setSelectedCity', selectedOptions[1].text);
  store.commit('setSelectedRegion', selectedOptions[1].value);

  fetchData({
    "region": selectedRegion.value,
    'latitude': userLatitude.value,
    "longitude": userLongitude.value,
    "keyword": searchQuery.value,
    "type": typeValue.value
  })
  // console.log('Selected City Code:', cityCode);
  showArea.value = false;
};

const onConfirm = ({selectedOptions}) => {
  showArea.value = false;

  const city = selectedOptions.find(option => city_list[option.value]);

  // const city = selectedOptions.find(option => option.level === 'city_list');

  console.log(city)

  // selectedCity.value = selectedOptions.map((item) => item.text).join('/');
};

const selectItem = ref(null);
const guide = (item) => {
  console.log("点击导航", item)
  selectItem.value = item
  showShare.value = true;
};

const detail = (item) => {
  console.log("点击详情", item)
  router.push({path: '/detail', query: {id: item.id, name: item.name}})
};

const showShare = ref(false);
const options = [
  {
    name: '高德',
    icon: require('@/assets/icon/高德地图.png'),
  },
  {
    name: '百度',
    icon: require('@/assets/icon/百度地图.png'),
  },
  {
    name: '腾讯',
    icon: require('@/assets/icon/腾讯地图.png'),
  }
];

const onSelect = (option) => {
  switch (option.name) {
    case '高德':
      window.open(`https://uri.amap.com/marker?position=${selectItem.value.longitude},${selectItem.value.latitude}&name=${encodeURIComponent(selectItem.value.name)}&callnative=1`, '_blank');
      break;
    case '百度':
      const x_pi = 3.14159265358979324 * 3000.0 / 180.0;
      const z = Math.sqrt(selectItem.value.longitude * selectItem.value.longitude + selectItem.value.latitude * selectItem.value.latitude) + 0.00002 * Math.sin(selectItem.value.latitude * x_pi);
      const theta = Math.atan2(selectItem.value.latitude, selectItem.value.longitude) + 0.000003 * Math.cos(selectItem.value.longitude * x_pi);
      const bdLon = z * Math.cos(theta) + 0.0065;
      const bdLat = z * Math.sin(theta) + 0.006;
      window.open(`http://api.map.baidu.com/marker?location=${bdLat},${bdLon}&title=${encodeURIComponent(selectItem.value.name)}&output=html`, '_blank');
      break;
    case '腾讯':
      window.open(`https://apis.map.qq.com/uri/v1/marker?marker=coord:${selectItem.value.latitude},${selectItem.value.longitude};title:${encodeURIComponent(selectItem.value.name)}`, '_blank');
      break;
  }
  showShare.value = false;
};

onMounted(() => {
  // const containerWidth = container.value.clientWidth;
  // const containerHeight = container.value.clientHeight;
  // const bubbleWidth = 50; // 气泡宽度
  // const bubbleHeight = 50; // 气泡高度

  navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position)
        userLatitude.value = position.coords.latitude;
        userLongitude.value = position.coords.longitude;
        console.log(`用户的经度: ${userLongitude.value}, 纬度: ${userLatitude.value}`);
        getCityCode({'latitude': userLatitude.value, "longitude": userLongitude.value})

      },
      (error) => {
        console.error("获取位置失败", error);
      }
  );

  // bubbleOffset.value = {
  //   x: containerWidth - bubbleWidth,
  //   y: containerHeight - bubbleHeight - 30
  // };


  // loadData()
});


</script>

<template>
  <div class="city-search">
    <div class="city-display" @click="showArea = true">
      {{ selectedCity }}
      <van-icon name="arrow-down" size="13"/>
    </div>
    <div class="search-container">
      <input
          v-model="searchQuery"
          class="search-input"
          type="text"
          placeholder="输入关键词搜索"
          @keyup.enter="triggerSearch"
          @blur="triggerSearch"
      />
    </div>
  </div>
  <van-popup v-model:show="showArea" position="bottom">
    <van-area
        :area-list="cityList"
        columns-num="2"
        @confirm="onAreaConfirm"
        @cancel="showArea = false"
    />
  </van-popup>
  <van-swipe class="img-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item>
      <img src="@/assets/images/b1.jpg" style="width: 100%;">
    </van-swipe-item>
  </van-swipe>

  <van-dropdown-menu>
    <van-dropdown-item v-model="typeValue" :options="typeOption" @change="typeChange"/>
    <van-dropdown-item v-model="value2" :options="option2"/>
  </van-dropdown-menu>

  <div class="list-container">
    <van-overlay :show="loading">
      <div class="wrapper">
        <van-loading type="spinner" color="#0094ff" size="24px" vertical>
          数据加载中...
        </van-loading>
      </div>
    </van-overlay>
    <div v-if="list.length === 0" style="width:100%; text-align: center">
      <van-empty image="search" image-size="100" description="此地空空">
        <van-button round type="primary" class="bottom-button" @click="navigateToLocation">创建地点</van-button>
      </van-empty>
    </div>
    <div v-else class="content-container" v-for="(item, index) in list" :key="index">
      <div class="image">
        <img :src="item.coverPath ? `${baseUrl}${item.coverPath}` : require('@/assets/images/cover_wc.png')"
             alt="Example Image" style="width: 100%; height: 100%;">
      </div>
      <div class="text-container">
        <div class="text-block-1">{{ item.name }}</div>
        <div class="text-block-2">
          <van-icon name="flag-o" :size="14" color="#959BB0"/>
          <span style="font-size: 14px; color: #959BB0;">地址:{{ item.address || '待补充' }}</span>
        </div>
        <div class="text-block-2">
          <van-icon name="location-o" :size="14" color="#959BB0"/>
          <span style="font-size: 14px; color: #959BB0;">距你:{{ item.distance.toFixed(2) }} km</span>
        </div>
        <div class="text-block-3">

          <div class="inner-block">
<!--            <van-icon name="guide-o" color="#38b9fa" size="20" />-->
            <van-button icon="guide-o" type="primary" size="mini"
                        @click="guide(item)">
              导航
            </van-button>
          </div>
          <div class="inner-block">
            <van-button icon="chat-o" type="primary" size="mini"
                        @click="detail(item)">
              评论
            </van-button>
<!--            <van-icon name="arrow" color="#38b9fa" size="20" @click="detail(item)"/>-->
          </div>

        </div>
      </div>
    </div>
  </div>
  <van-share-sheet
      v-model:show="showShare"
      title="导航"
      :options="options"
      @select="onSelect"
  />
</template>

<style scoped>
.van-dropdown-menu {
  --van-dropdown-menu-height: 4vh;
}

.city-search {
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #38b9fa;
  /*background: linear-gradient(90deg, #FF6666, #FF9966, #FFFF66, #66FF66, #66B2FF, #B266FF); */
  padding: 10px 10px;
  height: 3vh;
}

.img-swipe {
  width: 100%;
  height: 15vh;
}

.list-container {
  width: 100%;
  height: 67vh;
  background-color: #EDF0F0;
  padding-top: 10px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
}


.city-display {
  flex: 0 0 70px;
  padding: 5px 1px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  color: white;
  border-radius: 20px;
}

.search-container {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.search-input {
  flex: 1;
  border: none;
  border-radius: 20px;
  font-size: 13px;
  outline: none;
  height: 10px;
  padding: 10px 20px;
}

.location-list {
  display: flex;
  justify-content: space-between;
  gap: 13px;
  padding: 0 5px;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
}


.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.list-item {
  display: flex;
  /*align-items: center;*/
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 150px;
}


.bottom-button {
  width: 160px;
  height: 40px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Flex 容器 */
.content-container {
  display: flex; /* 启用 Flex 布局 */
  align-items: center; /* 垂直居中对齐 */
  padding: 5px;
  background-color: #f9f9f9; /* 背景颜色 */
  width: 96%; /* 防止内容超出屏幕 */
  box-sizing: border-box; /* 包括 padding 在内的宽度计算 */
  margin-top: 5px;
  margin-left: 2%;
}

/* 图片样式 */
.image {
  width: 150px; /* 固定宽度 */
  height: 130px; /* 固定高度 */
  background-color: #ddd; /* 占位背景颜色 */
  margin-right: 20px; /* 与文字块之间的间距 */
  flex-shrink: 0;
}

/* 文字块容器 */
.text-container {
  display: flex;
  flex-direction: column; /* 垂直排列文字块 */
  justify-content: space-around; /* 均匀分布文字块 */
  height: 150px; /* 与图片高度一致 */
  flex-grow: 1; /* 允许文字容器扩展 */
  max-width: calc(100% - 170px); /* 防止文字超出容器宽度 */
}

/* 文字块样式 */
.text-block-1 {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 使用省略号表示超出的文本 */
  max-width: 100%; /* 防止超出容器宽度 */
}

.text-block-2 {
  font-size: 16px;
  color: #555;
  max-width: 100%; /* 设置最大宽度 */
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 使用省略号表示超出的文本 */

}

.text-block-3 {
  font-size: 14px;
  color: #777;
  display: flex; /* 使用 Flex 布局 */
  justify-content: space-between; /* 块之间均匀分布 */
}

/* 内部块样式 */
.inner-block {
  width: 50%; /* 每个块占据 30% 的宽度 */
  text-align: left;
  color: #fff;
}

</style>

