<template>
  <div id="map-head">
    <van-row align="center" style="background-color: #FFFFFF">
      <van-col span="6">
        <div class="city-display" @click="showPicker = true">
          {{ selectedCity }}
          <van-icon name="arrow-down" size="13"/>
        </div>
        <!--        <van-field-->
        <!--            v-model="result"-->
        <!--            is-link-->
        <!--            name="picker"-->
        <!--            placeholder="城市"-->
        <!--            @click="showPicker = true"-->
        <!--            class="custom-field"-->
        <!--        />-->
        <van-popup v-model:show="showPicker" position="bottom">
          <van-area
              :area-list="cityList"
              columns-num="2"
              @confirm="onAreaConfirm"
              @cancel="showPicker = false"
          />
        </van-popup>

        <!--        <van-popup v-model:show="showPicker" position="bottom">-->
        <!--          <van-picker-->
        <!--              :columns="columns"-->
        <!--              @confirm="onConfirm"-->
        <!--              @cancel="showPicker = false"-->
        <!--          />-->
        <!--        </van-popup>-->
      </van-col>
      <van-col span="18">
        <van-search
            v-model="keyword"
            shape="round"
            class="custom-search"
            placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>
  </div>
  <van-overlay :show="loading">
    <div class="wrapper">
      <van-loading type="spinner" color="#0094ff" size="24px" vertical>
        数据加载中...
      </van-loading>
    </div>
  </van-overlay>

  <div id="map-container"></div>
  <van-dialog v-model:show="dialog.show" :title="dialog.title">
    <div style="text-align: center">
      <img :src="dialog.img" style="height: auto; width: 200px" alt=""/>
    </div>
  </van-dialog>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {event} from 'vue-gtag'
import axios from '@/axios';
import {cityList} from "@/utils/utils";
import {useStore} from "vuex";
const store = useStore();
const selectedCity = computed(() => store.getters.getSelectedCity);
const selectedRegion = computed(() => store.getters.getSelectedRegion);

const userLatitude = ref(0.0);
const userLongitude = ref(0.0);

const dialog = ref({
  show: false,
  title: '',
  img: '',
  content: '',
});

const map = ref(null);
const markerMode = ref(false);
const showPicker = ref(false);
const keyword = ref('');
const loading = ref(false);
const districtLayer = ref(null);


const iconMapping = {
  park: require('@/assets/images/park.png'),
  bar: require('@/assets/images/bar-1.png'),
  wc: require('@/assets/images/wc.png'),
  bath: require('@/assets/images/bath.png'),
};

const onAreaConfirm = async ({selectedValues, selectedOptions, selectedIndexes}) => {
  console.log(selectedOptions)
  store.commit('setSelectedCity', selectedOptions[1].text);
  store.commit('setSelectedRegion', selectedOptions[1].value);

  try {
    const response = await axios.get('/cityCenter', {
      params: {
        code: selectedRegion.value
      },
    });

    console.log(response.data.data.lat);
    console.log(response.data.data.lng);
    if (map.value) {
      map.value.setCenter([response.data.data.lng, response.data.data.lat]);
    }

    // map.setCenter(new TMap.LatLng(response.data.data.lat, response.data.data.lng));
    fetchData();

  } catch (error) {
    console.error("Error fetching data: ", error);
  }


  // fetchData({
  //   "region": selectedRegion.value,
  //   'latitude': userLatitude.value,
  //   "longitude": userLongitude.value,
  //   "keyword": searchQuery.value,
  //   "type": typeValue.value
  // })
  // console.log('Selected City Code:', cityCode);
  showPicker.value = false;
};

const initMap = () => {
  event('about', {method: 'initMap'})
  map.value = new AMap.Map('map-container', {
    mapStyle: "amap://styles/macaron",
    zoom: 10,
  });
};

const fetchData = async () => {
  try {
    loading.value = true
    const response = await axios.get('/map/locations', {
      params: {
        region: selectedRegion.value,
        keyword: keyword.value.trim(),
      },
    });
    if (response.status === 200) {
      console.log('请求成功:', response.data);
      if (map.value) {
        map.value.clearMap(); // 清除所有标记点
      }

      loading.value = false
      const data = response.data.data;
      data.forEach(item => {
        const marker = new AMap.Marker({
          position: [item.longitude, item.latitude],
          map: map.value,
          title: item.Name,
          icon: new AMap.Icon({
            size: new AMap.Size(25, 50), // 图标大小
            image: iconMapping[item.type], // 自定义图标路径
            imageSize: new AMap.Size(25, 25), // 图标显示大小
          }),
        });

        marker.extData = {
          id: item.id,
        };

        marker.on('click', function (e) {
          axios.get(`/location/${e.target.extData.id}`)
              .then(response => {
                console.log(response.data)
                const d = response.data.data
                dialog.value.title = d.name
                dialog.value.img = process.env.VUE_APP_API_BASE_URL + d.coverPath
                dialog.value.show = true
                console.log(e.target)
              })
              .catch(error => {
                console.error('Error fetching location:', error);
              });
        });

      });
    } else {
      console.log('请求失败，状态码:', response.status);
    }

    if (districtLayer.value) {
      districtLayer.value.setMap(null);
      districtLayer.value = null;
    }

    districtLayer.value = new AMap.DistrictLayer.Province({
      zIndex: 15,
      zooms: [2, 15],
      adcode: selectedRegion.value,
      depth: 2,
      styles: {
        'fill': function (properties) {
          return 'rgba(173, 216, 230, 0.2)';
        },
        'province-stroke': function (properties) {
          return 'rgba(223,228,234,0.4)';
        },
        'city-stroke': function (properties) {
          return 'rgba(223,228,234,0.4)';
        },
        'county-stroke': function (properties) {
          return 'rgba(223,228,234,0.4)';
        }
      }
    });

    map.value.add(districtLayer.value)


  } catch (error) {
    console.error('Error fetching data', error);
  }
};

const handleMapClick = (e) => {
  if (markerMode.value) {
    const {lnglat} = e;
    new AMap.Marker({
      position: lnglat,
      map: map.value,
      title: '新增标记',
    });

    // 退出标记模式
    markerMode.value = false;
    map.value.off('click', handleMapClick);
  }
};

const getCityCode = async (params = {}) => {
  try {
    loading.value = true;
    const response = await axios.get('/location-geo', {
      params: params
    });

    if (response.status === 200) {
      selectedRegion.value = response.data.data.cityCode
      selectedCity.value = cityList.city_list[selectedRegion.value]
      console.log("getCityCode", selectedRegion.value)
      console.log("getCityCode", selectedCity.value)
      initMap();
      fetchData();

      // list.value = response.data.data;
      // loading.value = false;
    }
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};


onMounted(() => {
  // 获取用户所在城市
  navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position)
        userLatitude.value = position.coords.latitude;
        userLongitude.value = position.coords.longitude;
        console.log(`用户的经度: ${userLongitude.value}, 纬度: ${userLatitude.value}`);
        getCityCode({'latitude': userLatitude.value,  "longitude": userLongitude.value})
      },
      (error) => {
        console.error("获取位置失败", error);
      }
  );
});

</script>

<style scoped>

#map-head {
  width: 85%;
  height: 7vh;
  position: fixed;
  top: 2%;
  left: 5%;
  background-color: #f8f9fa;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;

  border-radius: 15px;
}

#map-container {
  width: 100%;
  height: 95vh
}

</style>